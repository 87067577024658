import React from 'react'
import Layout from '../../layout'
import "../../../styles/components/about/_about.scss"
import gatsbyLinkify from '../../../helpers/gatsbyLinkify';
import { graphql, useStaticQuery } from 'gatsby';

import dlIcon from '../../../assets/icon/dl_icon.png';

export default function AboutPage({pageContext}) {

    let content_obj = pageContext.content_obj;

    return (
        <Layout width_restrict_all={true} meta_title={'About'} meta_desc={"Find out more about Jonny, including his education and employment history."} meta_img={`/assets/metadata_images/default/about.png`}>
            <h1 className='page-header'>About</h1>
            <CreateSection obj={content_obj['about']} has_header={false} />
            <CreateSection obj={content_obj['work']} />
            <CreateSection obj={content_obj['education']} />
            <CreateSection obj={content_obj['dev skills']} />
            <CvSection />
        </Layout>
    )
}

function CreateSection({obj, has_header}) {


    return (
        <section onClick={gatsbyLinkify} id={`about-${obj.title.toLowerCase().trim()}`}>
            {has_header !== undefined ? null : <h2>{obj.title}</h2>}
            <div className={`flex-container ${obj.title.toLowerCase().trim()}-content`} dangerouslySetInnerHTML={{__html: obj.html}}>

            </div>
        </section>
    )
}

function CvSection({}) {

    
    let cv_path = useStaticQuery(graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "files"}, frontmatter: {title: {eq: "CV"}}}) {
                edges {
                    node {
                        frontmatter {
                            title
                            file {
                                publicURL
                            }
                        }
                    }   
                }
            }
        }
    `).allMarkdownRemark.edges[0].node.frontmatter.file.publicURL;

    return (
        <a href={cv_path} className='cv-download-button button m-width-100' download>
            <div className='cv-download-button__inner flex-container'>
                <span className='cv-download-button__text'>Download my CV</span>
                <div className='cv-download-button__img flex-container'>
                    <img src={dlIcon} alt=''/>
                </div>
            </div>
        </a>
    )

}